.wordfield-container {
    flex-direction: column;
    height: 100%;
    /* width: 48%; */
    flex: 1 1 0%;
    /* background-color: white; */
}

.tab {
    height: 4%;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    gap: 0.25rem;
}

.tab-button {
    height: 100%;
    background-color: rgb(115, 115, 115);
    outline: transparent;
    color: rgb(240, 240, 240);
    border: 0;
    border-radius: 0.25rem 0.25rem 0 0;
    font-weight: bold;
    padding: 0 0.5rem;
}

.cur-tab {
    color: rgb(115, 115, 115);
    background-color: rgb(240, 240, 240);
}

.tab-button:hover {
    cursor: pointer
}

.hint-field, .result-field {
    border-top-right-radius: 0.25rem;
}
.hint-field {
    display: flex;
    flex-direction: column;
    /* margin-top: 1%; */
    width: 100%;
    height: 96%;
    overflow: hidden;
    background-color: white;
}

.result-field {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 96%;
    
    background-color: white;
    /* gap: 0.1rem; */
}

.function-bar {
    padding: 1rem 1rem;
    display: inline-flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    width: 50%;
    height: 10%;
    gap: 1rem;
    font-size: 1.2rem;
}

.outputWord {
    /* position: relative; */
    padding: 0 1.5rem;
    /* font */
    font-size: 15px;
    line-height: 200%;
    height: 85%;
    /* padding */
    overflow: auto;
}

.function-bar-button {
    background: transparent;
    border: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100%;
    color: rgb(110, 114, 121);;
}

.function-bar-button:hover {
    opacity: 70%;
    cursor: pointer;
}

.button-green:hover {
    color: green;
}

.button-red:hover {
    color: red;
}