.input-textfield {
    vertical-align: bottom;
    resize: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    border: none;
    overflow: visible;
    /* background-color: red; */
    
    /* font */
    font-size: 15px;
    line-height: 200%;
    font-family: Microsoft JhengHei;
    
    /* padding */
    padding: 5px;
    background-color: rgb(240, 240, 240);
}

.input-textfield:focus-visible {
    outline: none;
}
