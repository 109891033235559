.LogInOutPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0;
}

.GoogleBtn {
    direction: ltr;
    font-weight: 500;
    height: auto;
    line-height: normal;
    max-width: 220px;
    min-height: 40px;
    padding: 8px 16px;
    text-align: left;
    width: 100%;
}

.Googleicon {
    height: 1rem;
    width: 1rem;
    max-width: 100%;
    border: none;
    display: inline-block;
    vertical-align: middle;
}

.GoogleLoginWordsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.GoogleLoginWords {
    color: #757575;
    display: table-cell;
    font-size: 14px;
    padding-left: 1rem;
    font-weight: bold;
}

.GreetingWords {
    color: white;
}

.hr {
    border-color: rgba(156, 163, 175, 1);
    margin: 0.75rem 0;
    width: 50%;
}

.LogoutPageBtns {
    display: flex;
    flex-direction: column;
    max-width: 18rem;
}

.LogoutBtn {
    color: rgba(209, 213, 219, 1);
    margin: 0.5rem 1rem;
    background-color: rgb(30, 41, 59);

    border: 0;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 3rem; */
    /* height: 3rem; */
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;

    font-size: 1.25rem;
    line-height: 1.75rem;
}

.LogoutBtn:hover {
    opacity: 70%;
    cursor: pointer;
}