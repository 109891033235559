.change-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    /* 以word為基準 */
    position: absolute;
    z-index: 5;
    margin-top: 1.6rem;
    font-size: 2rem;
    background-color: white;
  }
  
  .change-btn {
    padding: 0;
  }